import React, { Component } from "react"
import ClientSearch from "../components/ClientSearch"
import SiteContext from "../context/SiteContext"
import * as queryString from 'query-string';
class Search extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let site = 'finestone'
        
        this.context.changeSite(site)
    }

    // product bulletins, technical bulletins, system overviews/summaries, specifications, and details
    
    render() {
        const query = queryString.parse(this.props.location.search)
        const { pageContext } = this.props
        const { productData, categoryData, systemFamilyData, systemData, literatureData, options, site } = pageContext
        const { allProducts } = productData
        const { allCategories } = categoryData
        const { allSystems } = systemData
        const { allProductBulletins, allTechnicalBulletins, allSystemOverviews, allSpecifications, allDetails } = literatureData
        const products = allProducts.map(v => ({ ...v, type: 'product' }))
        const categories = allCategories.map(v => ({ ...v, type: 'category' }))
        const systems = allSystems.map(v => ({ ...v, type: 'system' }))
        const productBulletins = allProductBulletins.map(v => ({ ...v, type: 'productBulletin' }))
        const technicalBulletins = allTechnicalBulletins.map(v => ({ ...v, type: 'technicalBulletin' }))
        const systemOverviews = allSystemOverviews.map(v => ({ ...v, type: 'systemOverview' }))
        const specifications = allSpecifications.map(v => ({ ...v, type: 'specification' }))
        const details = allDetails.map(v => ({ ...v, type: 'details' }))

        return (
            <div>
                <div>
                    <ClientSearch query={query} products={products} categories={categories} systems={systems} productBulletins={productBulletins} technicalBulletins={technicalBulletins} systemOverviews={systemOverviews} specifications={specifications} details={details} engine={options} site={site}/>
                </div>
            </div>
        )
    }
}
export default Search